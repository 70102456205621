import { useFieldValidation } from '../useFieldValidation'
import {
  validateEmail,
  validateOptionalEmail,
} from '../useFieldValidation/validateEmail'
import type { EmailField } from './types'

export const useEmailField = (
  props?: Partial<{ required: boolean; key: string }>,
): EmailField => {
  const value = ref<string>('')

  const trimmedValue = computed<string>(() => value.value.trim())

  const { error: validationError, validate } = useFieldValidation<string>([
    [
      props?.required ? validateEmail : validateOptionalEmail,
      'field.email.error',
    ],
  ])

  return {
    data: reactive({
      key: props?.key || 'email',
      value,
      trimmedValue,
      validationError,
    }),

    actions: {
      validate,
    },
  }
}
